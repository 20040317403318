import React, { FC, Fragment, useEffect } from "react"
import {
  Row,
  Col,
  FormGroup,
  Input,
  Label,
  Card,
  Button,
  CardHeader,
  CardFooter,
  CardBody,
  CardTitle,
  CardText,
} from "reactstrap"
import { Formik } from "formik"

import DetailActionButtons from "../../../components/Buttons/DetailActionButtons"
import ErrorHandler from "../../../components/Alerts/ErrorHandler"
import RequiredLabel from "../../../components/Labels/RequiredLabel"

import schema from "../configs/schema"
import { FormikItem } from "../configs/types"
import useDistrictDetail from "../hooks/useDistrictDetail"

const basicInfoLeft: FormikItem[] = [
  {
    field: "name",
    isRequired: true,
    label: "Name",
  },
  {
    field: "address",
    label: "Address",
  },
]
const basicInfoRight: FormikItem[] = [
  {
    field: "subDomain",
    isRequired: true,
    label: "Sub domain",
  },
  {
    field: "connectionString",
    isRequired: true,
    label: "Connection string",
  },
]
const DistrictDetail: FC = () => {
  const { id, districtDetail, confirm, backToList } = useDistrictDetail()
  const header = !!id ? "Update district" : "Create district"

  return (
    <div className="animated fadeIn">
      <h4>{header}</h4>
      <Formik
        initialValues={districtDetail}
        enableReinitialize
        validationSchema={schema}
        onSubmit={(values) => {
          confirm(values)
        }}
      >
        {(formikProps) => {
          const {
            values,
            touched,
            errors,
            setFieldValue,
            handleChange,
            handleBlur,
            handleSubmit,
          } = formikProps

          return (
            <Fragment>
              <Row className="detail-buttons-padding">
                <Col md={12}>
                  <Row>
                    <Col md={6}>
                      {basicInfoLeft.map((item) => (
                        <FormGroup key={item.field}>
                          {item.isRequired ? (
                            <RequiredLabel text={item.label} />
                          ) : (
                            <Label>{item.label}</Label>
                          )}
                          <Input
                            type="text"
                            name={item.field}
                            value={(values as any)[item.field]}
                            onChange={handleChange}
                            onBlur={handleBlur(item.field)}
                            placeholder={item.label}
                          />
                          {item.isRequired &&
                            (errors as any)[item.field] &&
                            (touched as any)[item.field] && (
                              <ErrorHandler text={(errors as any)[item.field]} />
                            )}
                        </FormGroup>
                      ))}
                    </Col>
                    <Col md={6}>
                      {basicInfoRight.map((item) => (
                        <FormGroup key={item.field}>
                          {item.isRequired ? (
                            <RequiredLabel text={item.label} />
                          ) : (
                            <Label>{item.label}</Label>
                          )}
                          <Input
                            type="text"
                            name={item.field}
                            value={(values as any)[item.field]}
                            onChange={handleChange}
                            onBlur={handleBlur(item.field)}
                            placeholder={item.label}
                          />
                          {item.isRequired &&
                            (errors as any)[item.field] &&
                            (touched as any)[item.field] && (
                              <ErrorHandler text={(errors as any)[item.field]} />
                            )}
                        </FormGroup>
                      ))}
                    </Col>
                  </Row>
                </Col>
                <Col md={12}>
                  {!!values.schools && values.schools.length > 0 && (
                    <Label>School list</Label>
                  )}
                  <Row>
                    {!!values.schools &&
                      values.schools?.map((school) => (
                        <Col md={6}>
                          <Card>
                            <CardHeader className="font-weight-bold">
                              {school.name}
                            </CardHeader>
                            <CardBody>
                              <CardText>
                                <div>Address : {school.address}</div>
                                <div>
                                  Contact person email :{" "}
                                  {school.contactPersonEmail}
                                </div>
                                <div>
                                  Contact person name :{" "}
                                  {school.contactPersonName}
                                </div>
                                <div>Sub domain : {school.subDomain}</div>
                                <div>
                                  Connection string : {school.connectionString}
                                </div>
                                <div>
                                  Number of admins : {school.numberOfAdmins}
                                </div>
                                <div>
                                  Number of assistants :{" "}
                                  {school.numberOfAssistants}
                                </div>
                                <div>
                                  Number of students : {school.numberOfStudents}
                                </div>
                                <div>
                                  Number of teachers : {school.numberOfTeachers}
                                </div>
                                {school.pricingPlan && (
                                  <div>
                                    <div>
                                      Pricing plan name:{" "}
                                      {school.pricingPlan.name}
                                    </div>
                                  </div>
                                )}
                              </CardText>
                            </CardBody>
                          </Card>
                        </Col>
                      ))}
                  </Row>
                </Col>
              </Row>
              <DetailActionButtons
                confirmText={!!id ? "Update" : "Create"}
                onConfirm={handleSubmit}
                onCancel={backToList}
              />
            </Fragment>
          )
        }}
      </Formik>
    </div>
  )
}

export default DistrictDetail
