import React, { FC, Fragment } from "react"
import { Row, Col, FormGroup, Input, Label, Card, CardHeader, CardBody } from "reactstrap"
import { Formik } from "formik"
import { RiArrowRightSLine, RiArrowDownSLine } from "react-icons/ri"

import DetailActionButtons from "../../../components/Buttons/DetailActionButtons"
import ErrorHandler from "../../../components/Alerts/ErrorHandler"
import RequiredLabel from "../../../components/Labels/RequiredLabel"
import DistrictSelector from "../components/DistrictSelector"
import PricingPlanSelector from "../components/PricingPlanSelector"
import PricingPlanInformation from "../components/PricingPlanInformation"

import schema from "../configs/schema"
import { FormikItem } from "../configs/types"
import useSchoolDetail from "../hooks/useSchoolDetail"
import UploadFileButton from "../../../components/Buttons/UploadFileButton"
const DEFAULT_IMAGE_URL = "https://tleliteracy.com/wp-content/uploads/2017/02/default-avatar.png"

const ICON_SIZE = 20

const basicInfo: FormikItem[] = [
  {
    field: "name",
    isRequired: true,
    label: "Name",
  },
  {
    field: "address",
    label: "Address",
  },
  {
    field: "contactPersonEmail",
    isRequired: true,
    label: "Contact person email",
  },
  {
    field: "contactPersonName",
    label: "Contact person name",
  },
  {
    field: "subDomain",
    isRequired: true,
    label: "Sub domain",
  },
  {
    field: "connectionString",
    isRequired: true,
    label: "Connection string",
  },
]

const pricingPlanInfo: FormikItem[] = [
  {
    field: "numberOfAdmins",
    label: "Number of admins",
  },
  {
    field: "numberOfAssistants",
    label: "Number of assistants",
  },
  {
    field: "numberOfStudents",
    label: "Number of students",
  },
  {
    field: "numberOfTeachers",
    label: "Number of teachers",
  },
]

const keyInfo: FormikItem[] = [
  {
    field: "googleRecaptchaId",
    label: "Google Recaptcha Site Key"
  },
  {
    field: "googleRecaptchaSecretId",
    label: "Google Recaptcha Secret Key"
  },
  {
    field: "googleDriveServiceAccountEmail",
    label: "Google drive service account email"
  },
  {
    field: "googleDriveFileName",
    label: "Google drive certificate file name"
  },
  {
    field: "googleClientId",
    label: "Google Client Id"
  },
  {
    field: "googleClientSecret",
    label: "Google Client Secret"
  },
  {
    field: "pusherCluster",
    label: "Pusher cluster"
  },
  {
    field: "pusherAppId",
    label: "Pusher app ID"
  },
  {
    field: "pusherKey",
    label: "Pusher key"
  },
  {
    field: "pusherSecret",
    label: "Pusher secret"
  },
  {
    field: "freshDeskDomain",
    label: "Freshdesk domain"
  },
  {
    field: "freshDeskApiKey",
    label: "Freshdesk API key"
  },
  {
    field: "freshDeskPath",
    label: "Freshdesk path"
  },
  {
    field: "freshDeskPassword",
    label: "Freshdesk password"
  }
]

const SchoolDetail: FC = () => {
  const {
    id,
    schoolDetail,
    pricingPlanIsOver,
    isOpenKey,
    setOpenKey,
    confirm,
    backToList,
    file,
    setFile,
    setEditedTrue } = useSchoolDetail()
  const header = !!id ? "Update school" : "Create school"

  return (
    <div className="animated fadeIn">
      <h4>{header}</h4>
      <Formik
        initialValues={schoolDetail}
        enableReinitialize
        validationSchema={schema}
        onSubmit={(values: any) => {
          confirm(values)
        }}
      >
        {(formikProps) => {
          const {
            values,
            touched,
            errors,
            setFieldValue,
            handleChange,
            handleBlur,
            handleSubmit,
          } = formikProps

          return (
            <Fragment>
              <Row className="detail-buttons-padding">
                <Col md={6}>
                  <div className="mb-3">
                    <UploadFileButton
                      setFile={setFile}
                      fileName={
                        values.logo
                      }
                      onAddImage={setEditedTrue}
                      canDelete
                      onDelete={() => {
                        setFile(null)
                        setFieldValue(
                          "logo",
                          ""
                        )
                        setEditedTrue()
                      }}
                    />
                    {!file &&
                      !values.logo && (
                        <img
                          src={DEFAULT_IMAGE_URL}
                          style={{ maxWidth: "100%", width: "50%" }}
                        />
                      )}
                  </div>
                  {basicInfo.map((item) => (
                    <FormGroup key={item.field}>
                      {item.isRequired ? (
                        <RequiredLabel text={item.label} />
                      ) : (
                        <Label>{item.label}</Label>
                      )}
                      <Input
                        type="text"
                        name={item.field}
                        value={(values as any)[item.field]}
                        onChange={handleChange}
                        onBlur={handleBlur(item.field)}
                        placeholder={item.label}
                      />
                      {item.isRequired &&
                        (errors as any)[item.field] &&
                        (touched as any)[item.field] && (
                          <ErrorHandler text={(errors as any)[item.field]} />
                        )}
                    </FormGroup>
                  ))}
                  <Label>District</Label>
                  <DistrictSelector
                    districtId={values.districtId}
                    onChange={(e: any) => {
                      setFieldValue("districtId", (e && e.value) || "")
                    }}
                  />
                  <Card className="mt-2">
                    <CardHeader className="d-flex align-items-center justify-content-between">
                      <span>School keys</span>
                      <div className="cursor-pointer" onClick={() => setOpenKey(!isOpenKey)}>
                        {isOpenKey
                          ? <RiArrowDownSLine size={ICON_SIZE} />
                          : <RiArrowRightSLine size={ICON_SIZE} />}
                      </div>
                    </CardHeader>
                    {isOpenKey && <CardBody>
                      {keyInfo.map((item) => (
                        <FormGroup key={item.field}>
                          <Label>{item.label}</Label>
                          <Input
                            type="text"
                            name={item.field}
                            value={(values as any)[item.field]}
                            onChange={handleChange}
                            onBlur={handleBlur(item.field)}
                            placeholder={item.label}
                          />
                          {(errors as any)[item.field] &&
                            (touched as any)[item.field] && (
                              <ErrorHandler text={(errors as any)[item.field]} />
                            )}
                        </FormGroup>
                      ))}
                    </CardBody>}
                  </Card>
                </Col>
                <Col md={6}>
                  <FormGroup>
                    <Label>Pricing plan</Label>
                    <PricingPlanSelector
                      pricingPlanId={values.pricingPlanId}
                      onChange={(e: any) => {
                        const pricingPlan = e
                          ? {
                            id: e.value,
                            name: e.label,
                            numberOfAdmins: e.numberOfAdmins,
                            numberOfAssistants: e.numberOfAssistants,
                            numberOfStudents: e.numberOfStudents,
                            numberOfTeachers: e.numberOfTeachers,
                          }
                          : ""

                        setFieldValue("pricingPlan", pricingPlan)

                        if (pricingPlan) {
                          setFieldValue("pricingPlanId", pricingPlan.id)
                          if (
                            values.numberOfAdmins +
                            values.numberOfAssistants +
                            values.numberOfStudents +
                            values.numberOfTeachers ===
                            0
                          ) {
                            setFieldValue(
                              "numberOfAdmins",
                              pricingPlan.numberOfAdmins
                            )
                            setFieldValue(
                              "numberOfAssistants",
                              pricingPlan.numberOfAssistants
                            )
                            setFieldValue(
                              "numberOfTeachers",
                              pricingPlan.numberOfTeachers
                            )
                            setFieldValue(
                              "numberOfStudents",
                              pricingPlan.numberOfStudents
                            )
                          }
                        } else {
                          setFieldValue("pricingPlanId", null)
                          setFieldValue("numberOfAdmins", 0)
                          setFieldValue("numberOfAssistants", 0)
                          setFieldValue("numberOfTeachers", 0)
                          setFieldValue("numberOfStudents", 0)
                        }
                      }}
                    />
                  </FormGroup>
                  <PricingPlanInformation
                    pricingPlan={values.pricingPlan}
                    pricingPlanIsOver={pricingPlanIsOver}
                  />
                  {pricingPlanInfo.map((item) => (
                    <FormGroup key={item.field}>
                      <Label>{item.label}</Label>
                      <Input
                        type="number"
                        value={(values as any)[item.field] || ""}
                        onChange={(e) =>
                          setFieldValue(
                            item.field,
                            e.target.value ? parseInt(e.target.value) : 0
                          )
                        }
                        placeholder={item.label}
                      />
                    </FormGroup>
                  ))}
                  <FormGroup check>
                    <Input
                      type="checkbox"
                      checked={values.isEnableAutoSendWelcomeMail}
                      onChange={(e: any) => {
                        setFieldValue(
                          "isEnableAutoSendWelcomeMail",
                          !values.isEnableAutoSendWelcomeMail
                        )
                      }}
                      onBlur={handleBlur("isEnableAutoSendWelcomeMail")}
                    />
                    {' '}
                    <Label check>
                      Send welcome new student email automatically
                    </Label>
                  </FormGroup>
                  <FormGroup check>
                    <Input
                      type="checkbox"
                      checked={values.isEnableIEP}
                      onChange={(e: any) => {
                        setFieldValue(
                          "isEnableIEP",
                          !values.isEnableIEP
                        )
                      }}
                      onBlur={handleBlur("isEnableIEP")}
                    />
                    {' '}
                    <Label check>
                      Enable IEP
                    </Label>
                  </FormGroup>
                  <FormGroup check>
                    <Input
                      type="checkbox"
                      checked={values.isEnableSurvey}
                      onChange={(e: any) => {
                        setFieldValue(
                          "isEnableSurvey",
                          !values.isEnableSurvey
                        )
                      }}
                      onBlur={handleBlur("isEnableSurvey")}
                    />
                    {' '}
                    <Label check>
                      Enable Survey
                    </Label>
                  </FormGroup>
                  <FormGroup check>
                    <Input
                      type="checkbox"
                      checked={values.isConversationOneToOne}
                      onChange={(e: any) => {
                        setFieldValue(
                          "isConversationOneToOne",
                          !values.isConversationOneToOne
                        )
                      }}
                      onBlur={handleBlur("isConversationOneToOne")}
                    />
                    {' '}
                    <Label check>
                      1-1 Conversation
                    </Label>
                  </FormGroup>
                  <FormGroup check>
                    <Input
                      type="checkbox"
                      checked={values.isEnableHelpButton}
                      onChange={(e: any) => {
                        setFieldValue(
                          "isEnableHelpButton",
                          !values.isEnableHelpButton
                        )
                      }}
                      onBlur={handleBlur("isEnableHelpButton")}
                    />
                    {' '}
                    <Label check>
                      Show help button
                    </Label>
                  </FormGroup>
                </Col>
              </Row>
              <DetailActionButtons
                confirmText={!!id ? "Update" : "Create"}
                onConfirm={handleSubmit}
                onCancel={backToList}
              />
            </Fragment>
          )
        }}
      </Formik>
    </div>
  )
}

export default SchoolDetail
