import { useState, useMemo, useCallback, useEffect } from "react"
import { useParams, useHistory } from "react-router-dom"
import { useDispatch } from "react-redux"

import { School } from "../../../types/School"
import { getById, create, update, systemUpload } from "../../../services/schoolService"
import { setLoading, setAlert } from "../../../redux/commons/action"

const defaultSchool: School = {
  logo: "",
  name: "",
  address: "",
  contactPersonEmail: "",
  contactPersonName: "",
  subDomain: "",
  connectionString: "",
  numberOfAdmins: 0,
  numberOfAssistants: 0,
  numberOfTeachers: 0,
  numberOfStudents: 0,
  pricingPlanId: null,
  pricingPlan: null,
  districtId: null,
  googleRecaptchaId: "",
  googleRecaptchaSecretId: "",
  googleDriveServiceAccountEmail: "",
  googleDriveFileName: "",
  googleClientId: "",
  googleClientSecret: "",
  pusherCluster: "",
  pusherAppId: "",
  pusherKey: "",
  pusherSecret: "",
  freshDeskDomain: "",
  freshDeskApiKey: "",
  freshDeskPath: "",
  freshDeskPassword: "",
  isEnableIEP: true,
  isEnableSurvey: false,
  isConversationOneToOne: true,
  isEnableHelpButton: true,
  isEnableAutoSendWelcomeMail: false
}

const SCHOOL_LIST_URL = "/schools"
const useSchoolDetail = () => {
  const { id } = useParams<{ id: string }>()
  const dispatch = useDispatch()
  const history = useHistory()
  const [schoolDetail, setSchoolDetail] = useState<School>(defaultSchool)
  const [isOpenKey, setOpenKey] = useState<boolean>(false)
  const [file, setFile] = useState<any>(null)
  const [edited, setEdited] = useState<boolean>(false)

  const setEditedTrue = async () => {
    localStorage.setItem(window.location.href, "true")
    setEdited(true)
  }

  const getData = useCallback(async () => {
    if (!id) return
    dispatch(setLoading(true))

    try {
      const res = await getById(parseInt(id))
      setSchoolDetail(res.data)
    } catch (err: any) {
      dispatch(
        setAlert({
          type: "danger",
          message: err.response?.data || err.message,
        })
      )
    }

    dispatch(setLoading(false))
  }, [id])

  const confirm = useCallback(
    async (data: School) => {
      if (!!file) {
        const formData = new FormData()
        const { name } = file
        formData.append("file", file, name)
        const res = await systemUpload(formData)
        const imageUrl = res?.data?.key
        data.logo = imageUrl
      }
      const next = !!id ? update : create
      dispatch(setLoading(true))

      try {
        await next(data)
        dispatch(
          setAlert({
            type: "success",
            message: `${!!id ? "Update" : "Create"} school successfully`,
          })
        )
        backToList()
      } catch (err: any) {
        dispatch(
          setAlert({
            type: "danger",
            message: err.response?.data || err.message,
          })
        )
      }

      dispatch(setLoading(false))
    },
    [id, file]
  )

  const backToList = useCallback(() => {
    history.push(SCHOOL_LIST_URL)
  }, [])

  const pricingPlanIsOver: boolean = useMemo(() => {
    const { pricingPlan } = schoolDetail
    if (!pricingPlan) return false
    return [
      "numberOfAdmins",
      "numberOfStudents",
      "numberOfTeachers",
      "numberOfAssistants",
    ].some((field) => (schoolDetail as any)[field] > (pricingPlan as any)[field])
  }, [schoolDetail])

  useEffect(() => {
    getData()
  }, [])

  return {
    id,
    schoolDetail,
    pricingPlanIsOver,
    isOpenKey,
    setOpenKey,
    confirm,
    backToList,
    file,
    setFile,
    setEditedTrue
  }
}

export default useSchoolDetail
